import { set_animate_pulse_classes } from '../../common/ui';
import { get_unit_conversion_possibilities } from '../../common/units';
import { Option } from './option';
import { format_number } from './option_table';

class OptionNumber extends Option {
  constructor(current, style, conf) {
    super(current, style, conf);
    this.is_dataset_option = !!this.style['dataset'];
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
    };
    return [edit_option_desc, 'option/edit_option_number'];
  }

  get_dataset(csunit = this.style.csunit) {
    let ret = this.style.dataset;
    const perc = this.style.initialDimension;
    if (!perc) {
      return ret;
    }
    const is_percent = (u) => {
      return get_unit_conversion_possibilities('percent');
    };
    if (is_percent(csunit) && !is_percent(this.style.unit)) {
      ret = ret.map((val) => (100 * val) / perc);
      ret = convert_value(ret, '%', csunit);
    } else if (!is_percent(csunit) && is_percent(this.style.unit)) {
      ret = convert_value(ret, csunit, '%');
      ret = ret.map((val) => (perc * val) / 100);
    }
    return ret;
  }

  change_unit(elem) {
    this.style['csunit'] = icvt[elem.value];
    if (this.style.csunit === this.style.unit) {
      this.style.csunit = undefined;
    }
    this.conf.log.modify_style({ csunit: this.style.csunit }, this.handle);
    this.refresh_view();
  }

  get_unit_conversion_possibilities() {
    return get_unit_conversion_possibilities(this.style.csunit || this.style.unit);
  }

  get_cvt_unit() {
    const unit = this.style.csunit || this.style.unit;
    return cvt[unit] || unit;
  }

  set_new_current(new_current) {
    this.conf.log.add_value(new_current, this.handle);
    this[this.is_dataset_option ? 'dataset' : 'current'] = new_current;
  }

  update_history() {
    if (!this.conf.is_support_page) {
      const val = this.is_dataset_option ? this.style['dataset'] : this.current_from_user();
      this.conf.log.add_value(val, this.handle, {}, this.is_dataset_option);
      this.conf.show_action_buttons();
      this.conf.value_changed();
    }
  }
}

class OptionFloat extends OptionNumber {
  get_current_widget_id() {
    return `${this.uid}_float_widget`;
  }

  is_editable_with_a_label_click() {
    return false;
  }

  set_table_input(editor, cell) {
    const num_edit = document.createElement('div');
    const table_num_template = require('../../view/option/currents/number_in_tables.handlebars');
    const table_num_html = table_num_template({
      handle: this.handle,
      value: cell.getValue(),
      min: this.style.min || '',
      max: this.style.max || '',
      step: this.style.step || '',
    });
    num_edit.insertAdjacentHTML('beforeend', table_num_html);
    editor.input_edit = num_edit.firstChild;
    return num_edit;
  }

  value_from_user(value) {
    if (value === 'None' || value === '') {
      return 0;
    }
    if (isNaN(parseFloat(value))) {
      return {
        error: 'Invalid value ' + '<b>' + value + '</b>' + ' in ' + this.handle,
      };
    }
    return parseFloat(value);
  }

  value_to_user(value) {
    if (!value || value === 'None') return globalThis.empty_data_placeholder;
    if (value.error) {
      const m = document.createElement('span');
      m.innerHTML = value.error;
      return m;
    }
    value = convert_value(value, this.style.unit, this.style.csunit);
    const prec = this.style['precision'] || '.6g';
    return format_number(value, prec);
  }

  get_unit() {
    const unit = globalThis.get_symbol(this.style.csunit || this.style.unit);
    return unit !== 'None' ? unit : '';
  }
}

class OptionInteger extends OptionNumber {
  get_current_widget_id() {
    return `${this.uid}_integer_widget`;
  }

  value_from_user(value, old = null) {
    var new_current = parseInt(value);
    if (isNaN(new_current)) {
      return old;
    }
    return new_current;
  }

  current_to_user_view() {
    const value = this.current_to_user(this.conf.log.get_last_value_of(this.handle));
    if (value === 0) return value;
    return value || globalThis.empty_data_placeholder;
  }
}

globalThis.option_types['Float'] = OptionFloat;
globalThis.option_types['Integer'] = OptionInteger;
