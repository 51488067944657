import { create_modal } from '../common/html_elements';
import { show_ephemeral_toast } from '../common/toast';
import { empty_element } from '../common/ui';
import {
  get_ynames,
  get_toolbox,
  get_value_axis,
  get_x_axis,
  convert_series_with_initial_dimensions,
  convert_series_without_initial_dimensions,
  update_markpoint_coords,
  get_markpoint_index_on_series,
} from './plot_functions';

class PlotCommands {
  constructor(attrs, chart, chart_name = 'Main') {
    this.config = attrs['config'];
    this.chart = chart;
    this.chart_name = chart_name;
    this.show_markpoints = false;
    this.markpoints = attrs['markpoints'];
    this.user_markpoints = [];
    this.document_type = attrs['class_name'];
    this.loading = false;
    this.datasets = attrs['datasets'];
    this.options = attrs['options'];
    this.units = {};
    this.ynames = get_ynames(this.datasets);
    this.initial_dimensions = attrs['initial_dimensions'];
    Object.values(this.options).map((opt) => (this.units[opt['handle']] = opt['style']['unit']));
    this.convert_percentages = convert().from('%').possibilities();
    this.convert_lengths = convert().from('μm').possibilities();
    this.series_click_functions = {
      new_markpoint: () => false,
      remove_jumps: () => false,
    };
  }

  do(func_name) {
    this[func_name](...Object.values(arguments).slice(1));
  }

  save(func_name) {
    if (this.loading || !this.config) {
      return false;
    }
    if (!func_name) {
      console.log('ERROR: record has no func_name', this);
      return false;
    }
    if (arguments[1].doc) {
      const doc = Object.assign({}, arguments[1].doc);
      const doc_to_save = {
        currents: { elapsed: doc.currents.elapsed },
        plots_configurations: doc.plots_configurations,
      };
      this.config['new_current'].push([this.chart_name, func_name, JSON.stringify(doc_to_save)]);
    } else {
      this.config['new_current'].push([this.chart_name, func_name, JSON.stringify(arguments[1])]);
    }
    this.config.apply_changes();
    return true;
  }

  load() {
    this.loading = true;
    if (this.config) {
      for (let row of this.config.current) {
        if (this.chart_name === row[0]) {
          this.do(row[1], JSON.parse(row[2]));
        }
      }
    }
    this.loading = false;
  }

  add_markpoint_to_line() {
    if (!this.show_markpoints) {
      show_ephemeral_toast('Enable markpoints to add');
      return;
    }
    const markpoint_name_template = require('../view/markpoint_name_dialog.handlebars');
    const markpoint_name_html = markpoint_name_template();
    create_modal(
      {
        id: 'plot_markpoint',
        title: _('Create new markpoint'),
        content: markpoint_name_html,
      },
      [
        {
          button_id: 'create_plot_markpoint_btn',
          button_text: _('Create'),
          func: () => this.create_plot_markpoint(...arguments),
          delete_modal: true,
        },
      ]
    );
    return true;
  }

  edit_markpoint() {
    const markpoint_value = arguments[0]['data']['value'];
    const markpoint_opt_name = arguments[0]['data']['name'];
    const series_idx = arguments[0]['seriesIndex'];
    const series_name = this.chart.getOption()['series'][series_idx]['name'];
    const series_id = this.chart.getOption()['series'][series_idx]['id'];
    const series_marks = this.markpoints[this.chart_name][series_id];
    if (series_marks.some((mark) => mark['name'] === markpoint_opt_name && mark['value'] === markpoint_value)) {
      show_ephemeral_toast('Cannot edit Meta markpoints');
      return;
    }
    const old_markpoint_desc = this.user_markpoints.filter((m) => m['series_idx'] === series_idx && m['value'] === markpoint_value)[0];
    const old_value = old_markpoint_desc['value'];
    const old_legend = old_markpoint_desc['legend'];
    const markpoint_name_template = require('../view/edit_markpoint_dialog.handlebars');
    const markpoint_name_html = markpoint_name_template({ old_value: old_value, old_legend: old_legend });
    create_modal(
      {
        id: 'edit_plot_markpoint',
        title: _('Edit markpoint'),
        content: markpoint_name_html,
      },
      [
        {
          button_id: 'edit_plot_markpoint_btn',
          button_text: _('Confirm'),
          func: () => this.confirm_markpoint_edit(...arguments, series_name, series_id),
          delete_modal: true,
        },
        {
          button_id: 'delete_plot_markpoint_btn',
          button_text: _('Delete'),
          func: () => this.delete_plot_markpoint(...arguments, series_name),
          delete_modal: true,
        },
      ]
    );
  }

  set_func_params_for_option_save(new_point = true) {
    const title_id = new_point ? 'markpoint_title' : 'new_markpoint_title';
    const value_id = new_point ? 'markpoint_value' : 'new_markpoint_value';
    const func_params = arguments[1];
    return {
      seriesIndex: func_params['seriesIndex'],
      data: func_params['data']['coord'] ? func_params['data']['coord'] : func_params['data'],
      markpoint_title: document.getElementById(title_id)?.value || func_params.markpoint_title,
      markpoint_value: document.getElementById(value_id)?.value || func_params.markpoint_value,
      seriesName: func_params['seriesName'] ? func_params['seriesName'] : arguments[2],
      seriesId: func_params['seriesId'] ? func_params['seriesId'] : arguments[3],
    };
  }

  set_option_and_save(new_series, func_name) {
    this.chart.setOption({ series: new_series });
    this.save(func_name, arguments[2]);
  }

  validate_user_markpoint(new_markpoint, series_id) {
    const user_values = this.user_markpoints.map((m) => {
      const mark_series = this.chart.getOption()['series'][m['series_idx']]['name'];
      if (mark_series === series_id) {
        return m['value'];
      }
    });
    const meta_values = this.markpoints[this.chart_name][series_id].map((m) => m['value']);
    const values = [...user_values, ...meta_values];
    if (values.includes(new_markpoint['value'])) {
      show_ephemeral_toast(`${_('Cannot add multiple markpoints with the same label')} <b>${new_markpoint['value']}</b>`);
      return false;
    }
    return true;
  }

  get_new_markpoint(func_params) {
    return {
      name: '',
      value: func_params.markpoint_title,
      coord: func_params.data,
      func_params: func_params,
    };
  }

  create_plot_markpoint() {
    const chart = this.chart;
    const func_params = this.set_func_params_for_option_save(true, ...arguments);
    const new_series = chart.getOption().series.map((s, index) => {
      const old_markpoints = s.markPoint?.data || [];
      if (index === func_params.seriesIndex) {
        const new_markpoint = this.get_new_markpoint(func_params);
        if (!this.validate_user_markpoint(new_markpoint, func_params['seriesId'])) {
          return {};
        }
        old_markpoints.push(new_markpoint);
        this.add_user_markpoint(new_markpoint, func_params['seriesName']);
      }
      return { markPoint: { data: old_markpoints } };
    });
    if (new_series.every((s) => s['markPoint'])) this.set_option_and_save(new_series, 'create_plot_markpoint', func_params);
    return true;
  }

  confirm_markpoint_edit() {
    let edit_error = false;
    const chart = this.chart;
    const func_params = this.set_func_params_for_option_save(false, ...arguments);
    const new_series = chart.getOption().series.map((s, index) => {
      const old_markpoints = s.markPoint?.data || [];
      if (index === func_params.seriesIndex) {
        const markpoint_to_update = old_markpoints.filter((m) => m.coord.toString() === func_params.data.toString())[0];
        const new_markpoint = this.get_new_markpoint(func_params);
        if (!this.validate_user_markpoint(new_markpoint, func_params['seriesId'])) {
          edit_error = true;
          return old_markpoints;
        }
        old_markpoints[old_markpoints.indexOf(markpoint_to_update)] = new_markpoint;
        this.remove_user_markpoint(markpoint_to_update, func_params['seriesName']);
        this.add_user_markpoint(new_markpoint, func_params['seriesName']);
      }
      return { markPoint: { data: old_markpoints } };
    });
    !edit_error && this.set_option_and_save(new_series, 'confirm_markpoint_edit', func_params);
    return true;
  }

  delete_plot_markpoint() {
    const chart = this.chart;
    const func_params = this.set_func_params_for_option_save(false, ...arguments);
    const new_series = chart.getOption().series.map((s, index) => {
      const old_markpoints = s.markPoint?.data || [];
      if (index === func_params.seriesIndex) {
        const markpoint_to_delete = old_markpoints.filter((m) => m.coord.toString() === func_params.data.toString())[0];
        old_markpoints.splice(old_markpoints.indexOf(markpoint_to_delete), 1);
        this.remove_user_markpoint(markpoint_to_delete, func_params['seriesName']);
      }
      return { markPoint: { data: old_markpoints } };
    });
    this.set_option_and_save(new_series, 'delete_plot_markpoint', func_params);
    return true;
  }

  add_element_to_legend(series_name, title, value) {
    const legend_container = document.getElementById(`${this.chart_name}_${series_name}_legend`);
    const div = document.createElement('p');
    div.id = `markpoint_${series_name}_${title}`;
    div.innerHTML = `${title}: ${value}`;
    legend_container.appendChild(div);
  }

  remove_element_from_legend(series_name, title) {
    document.getElementById(`markpoint_${series_name}_${title}`)?.remove();
  }

  add_user_markpoint(params, series_name) {
    const func_p = params['func_params'];
    this.user_markpoints.push({
      name: '',
      coord: func_p['data'],
      value: func_p['markpoint_title'],
      legend: func_p['markpoint_value'],
      func_params: func_p,
      series_idx: func_p['seriesIndex'],
    });
    this.add_element_to_legend(series_name, func_p['markpoint_title'], func_p['markpoint_value']);
  }

  remove_user_markpoint(params, series_name) {
    const deleting = this.user_markpoints.filter(
      (m) =>
        params['func_params']['seriesIndex'] === m['series_idx'] &&
        params['func_params']['markpoint_title'] === m['value'] &&
        params['func_params']['markpoint_value'] === m['legend']
    )[0];
    this.user_markpoints = this.user_markpoints.filter((um) => JSON.stringify(um) !== JSON.stringify(deleting));
    this.remove_element_from_legend(series_name, params['func_params']['markpoint_title']);
  }

  get_value_axis_unit(doc) {
    const is_hsm = doc.currents.instrument === 'hsm';
    return is_hsm ? 'micron^3' : 'micron';
  }

  create_markpoints_legend(series_name) {
    const legend_container = document.getElementById(`plot_${this.chart_name}_legend`);
    const legend_div = document.createElement('div');
    legend_div.id = `${this.chart_name}_${series_name}_legend`;
    const title = document.createElement('h6');
    title.innerHTML = `<b>${series_name}</b>`;
    legend_div.appendChild(title);
    legend_container.appendChild(legend_div);
  }

  meta_markpoints_legend(series_markpoints, series_name) {
    series_markpoints.forEach((s) => {
      this.add_element_to_legend(series_name, s['value'], s['parent_opt']);
    });
  }

  add_markpoints_to_series(series) {
    series.forEach((s) => {
      this.create_markpoints_legend(s['name']);
      const mark = [];
      mark.push(...this.markpoints[this.chart_name][s['id']]);
      if (mark.length) {
        s['markPoint'] = { data: mark };
        this.meta_markpoints_legend(s['markPoint']['data'], s['name']);
      }
    });
    this.user_markpoints.forEach((m) => {
      for (let [idx, s] of series.entries()) {
        if (m['series_idx'] === idx) {
          if (s['markPoint']) {
            s['markPoint']['data'].push(this.get_new_markpoint(m['func_params']));
          } else {
            s['markPoint'] = { data: [this.get_new_markpoint(m['func_params'])] };
          }
          this.add_element_to_legend(s['name'], m['value'], m['legend']);
        }
      }
    });
  }

  switch_markpoints(doc) {
    this.show_markpoints = !this.show_markpoints;
    const series = this.chart.getOption()['series'];
    empty_element(document.getElementById(`plot_${this.chart_name}_legend`));
    if (this.show_markpoints) {
      this.add_markpoints_to_series(series);
    } else {
      series.map((s) => (s['markPoint'] = { data: [] }));
    }
    this.chart.setOption(
      { series: series, toolbox: get_toolbox(doc, this, this.chart.getOption()['xAxis'][0]) },
      { replaceMerge: ['series', 'toolbox'] }
    );
    this.save('switch_markpoints', { doc: doc, markpoints: this.show_markpoints });
  }

  toggle_function(doc, button_state, function_name) {
    Object.keys(this.series_click_functions).forEach((k) => {
      this.series_click_functions[k] = () => false;
    });
    if (button_state) {
      this.series_click_functions[function_name] = (command) => {
        return (command && command()) || true;
      };
    }
    this.chart.setOption({ toolbox: get_toolbox(doc, this, this.chart.getOption()['xAxis'][0]) }, { replaceMerge: ['toolbox'] });
  }

  change_axis_unit() {
    let unit = arguments[1];
    if (!icvt[unit]) {
      unit = cvt[unit];
    }
    if (!icvt[unit]) {
      show_ephemeral_toast('Cannot convert unit', 'Info');
      return false;
    }
    const axis_dataset = this.get_dataset_from_axis_name(arguments[0]['name']);
    const series_initial_dimension = axis_dataset ? this.initial_dimensions[axis_dataset['handle']] : undefined;
    if (series_initial_dimension) {
      this.percent_to_value_unit_dialog(unit, arguments[0], series_initial_dimension);
    } else {
      this.change_unit_dialog(unit, arguments[0]);
    }
  }

  percent_to_value_unit_dialog(unit, axis_element, series_param) {
    const possibilities = [...this.convert_percentages, ...this.convert_lengths];
    const change_unit_template = require('../view/change_plot_unit_percent_micron.handlebars');
    const template = change_unit_template({
      from: unit,
      possibilities: JSON.stringify(possibilities),
      initial_dimension: series_param['initial_dimension'],
    });
    create_modal({ id: 'change_axis_unit', title: _('Change axis unit'), content: template }, [
      {
        button_id: 'change_axis_unit_btn',
        button_text: _('Confirm'),
        func: () => this.confirm_unit_change({ from: unit, axis_element: axis_element, initial_dimension: series_param['initial_dimension'] }),
        delete_modal: true,
      },
    ]);
  }

  change_unit_dialog(unit, axis_element) {
    const possibilities = convert().from(unit).possibilities();
    const change_unit_template = require('../view/change_plot_unit.handlebars');
    const template = change_unit_template({ from: unit, possibilities: JSON.stringify(possibilities) });
    create_modal({ id: 'change_axis_unit', title: _('Change axis unit'), content: template }, [
      {
        button_id: 'change_axis_unit_btn',
        button_text: _('Confirm'),
        func: () => this.confirm_unit_change({ from: unit, axis_element: axis_element }),
        delete_modal: true,
      },
    ]);
  }

  confirm_unit_change() {
    const axis_element = arguments[0]['axis_element'];
    const from = arguments[0]['from'];
    const to = document.getElementById('new_plot_unit')?.value || arguments[0]['to'];
    const initial_dimension = document.getElementById('initial_dimension_input')?.value || arguments[0]['initial_dimension'];
    const series = this.chart.getOption()['series'];
    if (initial_dimension) {
      this.change_unit_with_initial_dimension(axis_element, from, to, initial_dimension, series);
    } else {
      this.change_unit_without_initial_dimension(axis_element, from, to, series);
    }
  }

  change_unit_without_initial_dimension(axis_element, from, to, series) {
    const is_xAxis = axis_element['componentType'] === 'xAxis';
    const series_datasets = {};
    this.datasets.forEach((d) => (series_datasets[d['handle']] = d['name']));
    const series_to_convert = is_xAxis ? series : [];
    const series_to_keep = [];
    if (!is_xAxis) {
      series.forEach((el) => {
        if (axis_element['name'] === series_datasets[el['name']]) {
          series_to_convert.push(el);
        } else {
          series_to_keep.push(el);
        }
      });
    }
    const marks = { meta: this.markpoints[this.chart_name], user: this.user_markpoints };
    const converted_series = convert_series_without_initial_dimensions(series_to_convert, from, to, this.show_markpoints, marks, is_xAxis)[0];
    const new_series = [...converted_series, ...series_to_keep];
    this.save_unit_change(new_series, axis_element, from, to, { is_xAxis: is_xAxis });
  }

  change_unit_with_initial_dimension(axis_element, from, to, initial_dimension, series) {
    const series_to_convert = series.filter((s) => this.initial_dimensions[s['id']]);
    const series_to_keep = series.filter((s) => !series_to_convert.some((cs) => cs['id'] === s['id']));
    const marks = { meta: this.markpoints[this.chart_name], user: this.user_markpoints };
    const converted_series = convert_series_with_initial_dimensions(series_to_convert, from, to, initial_dimension, this.show_markpoints, marks)[0];
    const new_series = [...converted_series, ...series_to_keep];
    this.save_unit_change(new_series, axis_element, from, to, { i_dimension: initial_dimension });
  }

  save_unit_change(series, axis_element, from, to, save_options) {
    const axis_config = save_options['i_dimension']
      ? this.get_axis_config_for_save(axis_element, from, to, save_options['i_dimension'])
      : this.get_axis_config_for_save(axis_element, from, to);
    this.save('confirm_unit_change', axis_config);
    if (save_options['is_xAxis']) {
      this.xAxis_unit_changed(to, series, axis_element);
    } else {
      this.yAxis_unit_changed(to, series, axis_element);
    }
  }

  get_axis_config_for_save(axis_element, from, to, initial_dimension = undefined) {
    const elem = {
      name: axis_element['name'],
      yAxisIndex: axis_element['yAxisIndex'] || undefined,
      componentType: axis_element['componentType'],
    };
    const axis_config = { from: from, axis_element: elem, to: to };
    if (initial_dimension) {
      axis_config['initial_dimension'] = initial_dimension;
    }
    return axis_config;
  }

  yAxis_unit_changed(to, series, axis_element) {
    const y_axis_list = [];
    if (this.chart.getOption()['yAxis'].length > 1) {
      const old_y_axis = this.chart.getOption()['yAxis'];
      old_y_axis.forEach((ax) => y_axis_list.push(ax));
      const axis_index = axis_element['yAxisIndex'];
      old_y_axis[axis_index].axisLabel.formatter = `{value} ${to}`;
      old_y_axis[axis_index].unit = to;
      this.chart.setOption({ yAxis: old_y_axis, series: series }, { replaceMerge: ['yAxis', 'series'] });
    } else {
      const default_yAxis_dataset = this.datasets.filter((d) => d['handle'] !== 't' && this.ynames.includes(d['handle']))[0];
      const yAxis = get_value_axis(to, default_yAxis_dataset);
      yAxis.axisLabel.formatter = `{value} ${to}`;
      yAxis.unit = to;
      this.chart.setOption({ yAxis: yAxis, series: series }, { replaceMerge: ['yAxis', 'series'] });
    }
  }

  xAxis_unit_changed(to, series, axis_element) {
    const xAxis = get_x_axis(axis_element['name'], icvt[to]);
    xAxis.unit = to;
    this.chart.setOption({ xAxis: xAxis, series: series }, { replaceMerge: ['xAxis', 'series'] });
  }

  get_dataset_from_axis_name(axis_name) {
    // TODO: make it work even with axis_name === unit
    const series = this.chart.getOption()['series'];
    const series_ids = series.map((s) => s['id']);
    return this.datasets.filter((d) => series_ids.includes(d['handle']) && d['name'] === axis_name)[0];
  }

  get_points_differences(range_start, range_end, selected_series) {
    const differences = [];
    for (let i = range_start; i < range_end - 1; i++) {
      if (i >= 0 && i + 1 < selected_series.data.length) {
        const diff = selected_series.data[i + 1][1] - selected_series.data[i][1];
        differences.push(diff);
      }
    }
    return differences;
  }

  get_series_without_jump(differences, range_start, selected_series) {
    const absolute_differences = differences.map(Math.abs);
    const max_jump_index = absolute_differences.indexOf(Math.max(...absolute_differences));
    const max_jump = differences[max_jump_index];
    const adjustment_index = range_start + max_jump_index + 1;

    const adjusted_data = selected_series.data.map((point, index) => {
      if (index >= adjustment_index) {
        return [point[0], point[1] - max_jump];
      }
      return point;
    });
    return adjusted_data;
  }

  get_markpoints_without_jump(seriesId, original_selected_series, dataIndex, selected_series) {
    this.markpoints[this.chart_name][seriesId].forEach((m) => {
      const markpoint_index = get_markpoint_index_on_series(original_selected_series['data'], m['coord']);
      if (markpoint_index > dataIndex) {
        const new_coord = selected_series['data'][markpoint_index];
        update_markpoint_coords(m, new_coord[0], new_coord[1]);
      }
    });
    const that = this;
    this.user_markpoints.forEach((m) => {
      const markpoint_index = get_markpoint_index_on_series(original_selected_series['data'], m['coord']);
      if (markpoint_index > dataIndex) {
        const new_coord = selected_series['data'][markpoint_index];
        update_markpoint_coords(m, new_coord[0], new_coord[1]);
        const current = that.config['new_current'] || that.config['current'];
        current.forEach((e) => {
          if (e[1] === 'create_plot_markpoint') {
            const markpoint = JSON.parse(e[2]);
            if (markpoint['markpoint_title'] === m['value']) {
              markpoint['data'] = m['coord'];
              const updated_markpoint = JSON.stringify(markpoint);
              e[2] = updated_markpoint;
            }
          }
        });
      }
    });
    that.config.apply_changes(false);
  }

  update_option_without_jump(series) {
    const updating_dataset = this.options[series['id']];
    const ys = series['data'].map((c) => c[1]);
    updating_dataset['style']['dataset'] = ys;
    this.options[series['id']].update_history();
    return;
  }

  remove_jumps_from_line() {
    const params = arguments[0];
    const { seriesId, dataIndex } = params;

    const all_series = this.chart.getOption()['series'];
    const selected_series = all_series.find((s) => s['name'] === seriesId);
    const original_selected_series = { ...selected_series };

    const jump_range = 10;
    const range_start = dataIndex - jump_range;
    const range_end = dataIndex + jump_range;

    const differences = this.get_points_differences(range_start, range_end, selected_series);
    selected_series.data = this.get_series_without_jump(differences, range_start, selected_series);

    this.get_markpoints_without_jump(seriesId, original_selected_series, dataIndex, selected_series);
    if (this.show_markpoints) {
      selected_series.markPoint['data'] = [...this.markpoints[this.chart_name][seriesId], ...this.user_markpoints];
    }
    this.update_option_without_jump(selected_series);
    this.chart.setOption({ series: all_series }, { replaceMerge: ['series'] });
    return;
  }
}

export { PlotCommands };
