import { Option } from './option';
import { get_selected_db_id } from '../../common/hash';
import { set_opt_inner_html } from '../search_genome';

class OptionChooser extends Option {
  is_editable_with_a_label_click() {
    return false;
  }
  get_current_widget_id() {
    return `${this.uid}_chooser_widget`;
  }

  set_table_input(editor) {
    const options = this.style.options;
    const values = this.style.values;
    const select_options = options.map((opt) => ({ value: opt, text: values ? values[options.indexOf(opt)] : opt }));
    const chooser_edit = document.createElement('div');
    const table_chooser_template = require('../../view/option/currents/chooser_in_tables.handlebars');
    const table_chooser_html = table_chooser_template({ handle: this.handle, option: select_options });
    chooser_edit.insertAdjacentHTML('beforeend', table_chooser_html);
    editor.input_edit = chooser_edit.firstChild;
    return chooser_edit;
  }

  components_current_from_user() {
    this.new_current = this.input_edit.value;
    return this.new_current;
  }

  display_disabled(handle) {
    return handle === 'target_type' && !!this.conf.currents.docs.length;
  }

  value_to_user() {
    let ret = this.current;
    if (this.conf.class_name === 'SearchGenome' && this.handle === 'where') {
      ret = this.new_current;
    }
    if (this.style['values'] && this.style['options']) {
      if (this.style['options'].includes(ret)) {
        return _(this.style['values'][this.style['options'].indexOf(ret)]);
      }
    }
    if (this.style['values']) {
      const i = this.style['values'].indexOf(ret);
      ret = this.style['options'][i];
    }
    return _(ret);
  }

  current_from_user() {
    let input_element = document.getElementById(`${this.uid}_input`);
    if (!input_element) {
      return this.current;
    }
    if (this.attr.includes('MultiChooser')) {
      this.new_current = this.conf.search_doc_classes;
      if (!this.conf.search_doc_classes) {
        this.new_current = [];
      }
      return this.new_current;
    }

    this.new_current = input_element.value;
    if (this.conf.uid == 'def:Style#SearchReport#0' && this.handle == 'period') {
      const idx = this.style['options'].indexOf(this.new_current);
      this.new_current = this.style['values'][idx];
      return this.new_current;
    }
    if (this.conf.uid == 'def:Style#SearchGenome#0' && this.handle == 'qversions') {
      if (this.new_current == 'false') {
        this.new_current = false;
      }
      if (this.new_current == 'true') {
        this.new_current = true;
      }
    }
    return this.new_current;
  }

  chooser_value_changed(elem) {
    if (this.handle === 'target_type') return this.compare_target_change(elem);
  }

  compare_target_change(elem) {
    const value = elem.options[elem.selectedIndex].value;
    this.conf.styles['docs']['header'][0].target = [value];
    return true;
  }

  disable_chooser(current) {
    const input_element = document.getElementById(`${this.uid}_input`);
    if (current.length) {
      input_element.setAttribute('disabled', 'disabled');
    } else {
      input_element.removeAttribute('disabled');
    }
  }

  get_genome_chooser_options(class_name = this.conf.class_name) {
    const options_class = this.get_option_class(class_name);
    const is_option_checked = this.conf.search_doc_classes.length && this.conf.search_doc_classes.includes(class_name);
    return {
      value: class_name,
      class: options_class,
      text: set_opt_inner_html(class_name),
      option_checked: is_option_checked,
    };
  }

  get_option_class(class_name = this.conf.class_name) {
    return this.is_material(class_name)
      ? 'mChooser_materials'
      : this.is_analysis(class_name)
      ? 'mChooser_analysis'
      : this.is_report(class_name)
      ? 'mChooser_reports'
      : this.is_model(class_name)
      ? 'mChooser_models'
      : 'mChooser_other';
  }

  is_material(class_name) {
    const to_materials = ['Formulation', 'Compare'];
    return class_name.startsWith('Material') || to_materials.includes(class_name);
  }

  is_analysis(class_name) {
    const to_analysis = ['Batch', 'Sample'];
    return class_name.startsWith('Analysis') || to_analysis.includes(class_name);
  }

  is_report(class_name) {
    return class_name.startsWith('Report');
  }

  is_model(class_name) {
    return class_name.startsWith('Model');
  }

  is_manufacturing(class_name) {
    return ['Anomaly', 'AnomalyAnalysis'].includes(class_name);
  }

  get_genome_chooser_desc() {
    const skip = ['Material', 'Analysis', 'RegisteredInstrument', 'SearchReport', 'FieldNumberRegex', 'SearchManufacturing', 'Timer'];
    const skip_reports = ['Backup', 'User'];
    const values = this.style['options'];
    const valid_values = values.filter((val) => !skip_reports.some((s) => val.startsWith(s)) && !skip.includes(val));
    const materials = valid_values.filter((val) => this.is_material(val)).map((val) => this.get_genome_chooser_options(val));
    const analysis = valid_values.filter((val) => this.is_analysis(val)).map((val) => this.get_genome_chooser_options(val));
    const manufacturing = valid_values.filter((val) => this.is_manufacturing(val)).map((val) => this.get_genome_chooser_options(val));
    const others = valid_values
      .filter((val) => !this.is_material(val) && !this.is_analysis(val) && !this.is_report(val) && !this.is_manufacturing(val) && !this.is_model(val))
      .map((val) => this.get_genome_chooser_options(val));
    const reports = valid_values.filter((val) => this.is_report(val)).map((val) => this.get_genome_chooser_options(val));
    const models = valid_values.filter((val) => this.is_model(val)).map((val) => this.get_genome_chooser_options(val));
    return [materials, analysis, others, reports, models, manufacturing];
  }

  get_chooser_options_desc() {
    if (this.handle === 'period' && get_selected_db_id().startsWith('#demo/')) {
      this.current = 'All';
    }
    if (this.handle === 'target_type' && globalThis.glaze_search_table) {
      this.conf.styles['docs']['header'][0].target = [this.current];
    }
    const style_opts = this.style['options'];
    const style_vals = this.style['values'];
    const options = [];
    for (let idx of style_opts.keys()) {
      const show_value = style_vals ? style_vals[idx] : style_opts[idx];
      const selected = (style_vals && style_vals[idx] === this.current) || style_opts[idx] === this.current;
      options.push({
        value: style_opts[idx],
        text: show_value,
        opt_selected: selected,
      });
    }
    return options;
  }

  get_class_selector_selected_classes() {
    const selected_classes = this.conf.search_doc_classes.length > 0;
    return selected_classes ? this.conf.search_doc_classes : _('No classes selected');
  }

  template_input_edit_desc() {
    const edit_option_desc = {
      current_option_uuid: this.uuid,
      options: this.get_chooser_options_desc(),
    };
    return [edit_option_desc, 'option/edit_option_chooser'];
  }
}

class OptionMultiChooser extends OptionChooser {}

globalThis.option_types['Chooser'] = OptionChooser;
globalThis.option_types['OptionMultiChooser'] = OptionMultiChooser;

export { OptionMultiChooser };
